/* eslint-disable no-restricted-imports */
import { addMinutes, setHours, setMinutes, startOfDay } from "date-fns";
import { DayOfWeek } from "../reclaim-api/client";
import {
  CalendarEvent as CalendarEventDto,
  DelayStart,
  IconType as SchedulingLinkIconTypeDto,
  SchedulingLink as SchedulingLinkDto,
  SchedulingLinkMeetingAvailability as SchedulingLinkMeetingAvailabilityDto,
  SchedulingLinkMeetingAvailabilitySlot as SchedulingLinkMeetingAvailabilitySlotDto,
  SchedulingPriority as SchedulingLinkPriorityDto,
  TimePolicyType as TimePolicyTypeDto,
} from "../reclaim-api/scheduling-links/scheduling-links-client";
import { hashBoolean } from "../utils/dev";

export const mockBookingAvailabilitySlot = (
  baseDate: Date,
  hour: number,
  minute: number,
  durationMinutes: number
): SchedulingLinkMeetingAvailabilitySlotDto => {
  const now = startOfDay(new Date(baseDate));
  const start = setMinutes(setHours(now, hour), minute);
  const startTime = start.toISOString();
  const endTime = addMinutes(start, durationMinutes).toISOString();

  return {
    startTime,
    endTime,
    isSuggested: hashBoolean(startTime),
  };
};

export const mockCalendarEvent = (
  title: string,
  baseDate: Date,
  hour: number,
  minute: number,
  durationMinutes: number
): CalendarEventDto => {
  const now = startOfDay(new Date(baseDate));
  const start = setMinutes(setHours(now, hour), minute);
  const startTime = start.toISOString();
  const end = addMinutes(start, durationMinutes);
  const endTime = end.toISOString();
  const baseHash = `${startTime}-${endTime}-${title}`;

  return {
    title,
    startTime,
    endTime,
    eventId: baseHash,
    public: hashBoolean(`${baseHash}-public`),
    reclaimManaged: hashBoolean(`${baseHash}-reclaimManaged`),
  };
};

export const mockBookingAvailabilitySlots = (
  baseDate: Date,
  durationMinutes: number
): SchedulingLinkMeetingAvailabilitySlotDto[] => [
  mockBookingAvailabilitySlot(baseDate, 9, 30, durationMinutes),
  mockBookingAvailabilitySlot(baseDate, 10, 0, durationMinutes),
  mockBookingAvailabilitySlot(baseDate, 10, 30, durationMinutes),
  mockBookingAvailabilitySlot(baseDate, 12, 0, durationMinutes),
  mockBookingAvailabilitySlot(baseDate, 12, 30, durationMinutes),
  mockBookingAvailabilitySlot(baseDate, 13, 0, durationMinutes),
  mockBookingAvailabilitySlot(baseDate, 13, 30, durationMinutes),
];

export const mockBookingAvailability = (
  baseDate: Date,
  hasInviteeEvents = true
): SchedulingLinkMeetingAvailabilityDto => ({
  availableTimes: {
    15: mockBookingAvailabilitySlots(baseDate, 15),
    30: mockBookingAvailabilitySlots(baseDate, 30),
    45: mockBookingAvailabilitySlots(baseDate, 45),
  },
  inviteeEvents: hasInviteeEvents
    ? [
        mockCalendarEvent("Important meeting", baseDate, 11, 0, 60),
        mockCalendarEvent("Reading time", baseDate, 9, 30, 60),
        mockCalendarEvent("Lunch", baseDate, 12, 0, 45),
        mockCalendarEvent("Overlapping event", baseDate, 10, 0, 150),
      ]
    : undefined,
});

const timePolicy = {
  dayHours: {
    FRIDAY: {
      intervals: [
        {
          start: "09:00:00",
          end: "17:00:00",
        },
      ],
      endOfDay: "17:00:00",
    },
    MONDAY: {
      intervals: [
        {
          start: "08:00:00",
          end: "18:00:00",
        },
        {
          start: "19:00:00",
          end: "20:00:00",
        },
      ],
      endOfDay: "20:00:00",
    },
    TUESDAY: {
      intervals: [
        {
          start: "08:00:00",
          end: "18:00:00",
        },
        {
          start: "19:00:00",
          end: "20:00:00",
        },
        {
          start: "21:00:00",
          end: "22:00:00",
        },
      ],
      endOfDay: "22:00:00",
    },
    THURSDAY: {
      intervals: [
        {
          start: "09:00:00",
          end: "17:00:00",
        },
      ],
      endOfDay: "17:00:00",
    },
  },
  startOfWeek: DayOfWeek.MONDAY,
  endOfWeek: DayOfWeek.FRIDAY,
};

export const mockSchedulingLinkDto = (): SchedulingLinkDto => ({
  id: "8d8447d7-c93d-48d1-951d-fe6365944244",
  title: "30 minute meeting",
  description:
    "A long description of my meeting link. Sit dicta perspiciatis solutall am ut saepe et id facere omnis. Qui aspernatur necess itatibus quidem et in vel.",
  enabled: true,
  slug: "30-minute-meeting",
  organizer: {
    avatarUrl: "https://lh3.googleusercontent.com/a-/AFdZucojejfHkMEA5O6Sp_81QYNFKT1C1OV93XJzyhsH=s96-c",
    email: "reclaim.office2@gmail.com",
    name: "Michael Scott",
    userId: "ee8be6dc-7fc0-435c-9bb1-8d12c7c0b581",
  },
  coOrganizers: [],
  effectiveTimePolicy: timePolicy,
  timePolicyType: TimePolicyTypeDto.ONEOFF,
  hidden: false,
  delayStart: DelayStart.FROMNOWSTARTOFNEXTWEEK,
  oneOffPolicy: timePolicy,
  durations: [30],
  daysIntoFuture: 14,
  startDate: undefined,
  endDate: undefined,
  priority: SchedulingLinkPriorityDto.DEFAULT,
  conferenceType: undefined,
  iconType: SchedulingLinkIconTypeDto.BOLT,
  mainOrganizerId: "ee8be6dc-7fc0-435c-9bb1-8d12c7c0b581",
  organizers: [],
});

export const mockSchedulingLinkDtos = (): SchedulingLinkDto[] => {
  const archetype = mockSchedulingLinkDto();
  const description = "A short description of my meeting link.";

  return [
    {
      ...archetype,
      title: "Short Meeting",
      description,
      durations: [15, 30],
      daysIntoFuture: undefined,
      startDate: "2022-09-05T00:00:00.000-07:00",
      endDate: "2022-09-12T00:00:00.000-07:00",
      iconType: SchedulingLinkIconTypeDto.COFFEE,
      slug: "short-meeting",
    },
    archetype,
    {
      ...archetype,
      title: "Priority Meeting",
      timePolicyType: TimePolicyTypeDto.MEETING,
      oneOffPolicy: undefined,
      description,
      durations: [60, 90, 120],
      iconType: SchedulingLinkIconTypeDto.LIGHT,
      slug: "priority-meeting",
    },
  ];
};

// TODO - better / more realistic mock dates
export const unavailableUserDates = [
  new Date("2022-09-30T03:00:00.000Z"),
  new Date("2022-10-10T03:00:00.000Z"),
  new Date("2022-10-11T03:00:00.000Z"),
  new Date("2022-10-12T03:00:00.000Z"),
];
